<template>
  <b-modal id="certificatRGEmodal" ref="certificatRGEmodal" @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("certificat_rge") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRE">
      <div class="center">
        <b-form-group :label="$t('NUM_RGE') + '*'" label-for="num">
          <b-form-input
            id="num"
              :placeholder="$t('NUM_RGE')"
            v-model="v$.newCertificatRge.num.$model"
            :state="validateState('num')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="name-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newCertificatRge.num.$errors"
            id="name-feedback"
          ></error-handle>
          <div v-if="erreurlist.num" class="error-message">
            <ul v-if="Array.isArray(erreurlist.num)">
              <span v-for="(e, index) in erreurlist.num" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.num }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('date_attribution') + '*'" label-for="date_attribution">
          <b-form-input
            id="date_attribution"
            v-model="v$.newCertificatRge.date_attribution.$model"
            :state="validateState('date_attribution')"
              type="date"
            aria-describedby="date_attribution-feedback"
          ></b-form-input>
           <error-handle
            :list="v$.newCertificatRge.date_attribution.$errors"
            id="name-feedback"
          ></error-handle>
          <div v-if="erreurlist.date_attribution" class="error-message">
            <ul v-if="Array.isArray(erreurlist.date_attribution)">
              <span v-for="(e, index) in erreurlist.date_attribution" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.date_attribution }}</span>
          </div> 
        </b-form-group>

        <b-form-group :label="$t('date_expiration') + '*'" label-for="date_expiration">
          <b-form-input
            id="date_expiration"
            v-model="v$.newCertificatRge.date_expiration.$model"
            :state="validateState('date_expiration')"
            type="date"
            aria-describedby="date_expiration-feedback"
          ></b-form-input>
          <error-handle
          :list="v$.newCertificatRge.date_expiration.$errors"
          id="name-feedback"
        ></error-handle>
        <div v-if="erreurlist.date_expiration" class="error-message">
          <ul v-if="Array.isArray(erreurlist.date_expiration)">
            <span v-for="(e, index) in erreurlist.date_expiration" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.date_expiration }}</span>
        </div> 
        </b-form-group>
        <b-form-group :label="$t('pdf_rge') + '*'" label-for="pdf_rge" class="mb-2">
          <b-form-file
            id="pdf_rge"
            class="my-2 mx-0"
            :placeholder="$t('pdf_rge')"
            v-model="v$.newCertificatRge.pdf_rge.$model"
            :state="validateState('pdf_rge')"
              type="file"
            aria-describedby="pdf_rge-feedback"
          ></b-form-file>
           <error-handle
            :list="v$.newCertificatRge.pdf_rge.$errors"
            id="name-feedback"
          ></error-handle>
          <div v-if="erreurlist.pdf_rge" class="error-message">
            <ul v-if="Array.isArray(erreurlist.pdf_rge)">
              <span v-for="(e, index) in erreurlist.pdf_rge" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.pdf_rge }}</span>
          </div> 
        </b-form-group>
        <b-form-group :label="$t('CERTIFICAT_TYPE') + '*'">
          <b-form-radio-group v-model="radioOption" name="certificatType">
            <b-form-radio :value="'sousTraitant'">{{ $t('SOUS-TRAITANT') }}</b-form-radio>
            <b-form-radio :value="'entreprise'">{{ $t('COMPANY_ID') }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group :label="$t('SOUS-TRAITANT')" v-if="radioOption === 'sousTraitant'">
    
          <multiselect
            v-model="newCertificatRge.sousTraitant"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :placeholder="$t('SELECTED_SOUE-TRAITANT')"
            :options="getAllsousTraitant"
            label="name"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
         
        </b-form-group>
        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin && radioOption === 'entreprise'">
          <multiselect
            v-model="newCertificatRge.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
         
        </b-form-group>

        <b-form-group :label="$t('societe')" v-if="isSuperAdmin">
          <multiselect
            v-model="newCertificatRge.created"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
         
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRE">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingCertificatRge" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      radioOption:"sousTraitant",
      erreurlist: {
        num: null,
        date_attribution: null,
        pdf_rge:null,
        date_expiration: null,
      },
      newCertificatRge: {
        num: null,
        pdf_rge:null,
        date_attribution: null,
        date_expiration: null,
        entreprise: null,
        sousTraitant:null,
        created:null
      },
    
      page: 1,
      per_page: 1000,
      errorS: false,
      box: "",
    };
  },
  validations() {
    return {
      newCertificatRge: {
        num: { required},
        pdf_rge:{required},
        date_attribution: {
          required,
        },
        date_expiration: { required },
      },
    };
  },
  methods: {
    ...mapActions(["storeCertificatRge", "all_entreprises"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newCertificatRge[name];
      return $dirty ? !$error : null;
    },
    customLabel({ title }) {
      return `${title}`;
    },
    async addPRE() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        num: null,
        date_attribution: null,
        pdf_rge:null,
        date_expiration: null,
   
      };
      const data = {
        num: this.newCertificatRge.num,
        pdf_rge:this.newCertificatRge.pdf_rge,
        date_attribution: this.newCertificatRge.date_attribution,
        date_expiration: this.newCertificatRge.date_expiration,
        entreprise: this.newCertificatRge.entreprise ||null,
      
      };
    
  if (!this.isSuperAdmin) {
    if (this.newCertificatRge.sousTraitant) { 
       data.sous_traitant_id = this.newCertificatRge.sousTraitant.id;
      data.entreprise_id = null;
      data.created_by= this.getOnlineUser.entreprise.id;
    } else {
      data.sous_traitant_id =null;
      data.entreprise_id = this.getOnlineUser.entreprise.id;
      data.created_by= this.getOnlineUser.entreprise.id;
    }
  }  
   if(this.isSuperAdmin){
    if (this.newCertificatRge.sousTraitant) { 
       data.sous_traitant_id = this.newCertificatRge.sousTraitant.id;
      data.entreprise_id = null;

    } else {
      data.sous_traitant_id =null;
      data.entreprise_id = this.getOnlineUser.entreprise.id;
    }
    data.created_by= this.newCertificatRge.created.id;
   }

      await this.storeCertificatRge(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["certificatRGEmodal"].hide();
      this.newCertificatRge = {
        num: null,
        pdf_rge:null,
        date_attribution: null,
        date_expiration: null,
        entreprise: null,
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        num: null,
        pdf_rge:null,
        date_attribution: null,
        date_expiration: null,
        entreprise: null,
      
      };
    },

    close(bv) {
      if (this.$refs["certificatRGEmodal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  mounted() {
    if (this.isSuperAdmin)
      if (!this.getAllentreprises) {
        this.all_entreprises({
          page: this.page,
          per_page: this.perPage,
          search: this.search,
        });
      }
      if (!this.getAllsousTraitant) {
        this.allsousTraitant({
          page: this.page,
          per_page: this.perPage,
          search: this.search,
        });
      }
  },

  computed: {
    ...mapGetters([
      "getAllentreprises",
      "getAllsousTraitant",
      "getOnlineUser",
      "getLoadingCertificatRge",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
