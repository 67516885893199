import { render, staticRenderFns } from "./certificatRge.vue?vue&type=template&id=469324dc&scoped=true"
import script from "./certificatRge.vue?vue&type=script&lang=js"
export * from "./certificatRge.vue?vue&type=script&lang=js"
import style0 from "./certificatRge.vue?vue&type=style&index=0&id=469324dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469324dc",
  null
  
)

export default component.exports